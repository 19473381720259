import {
    VStack,
    Heading,
    Center,
    Box,
    useBreakpoint,
    Flex,
    Text,
    Container,
    HStack,
    Image,
    Button,
    useDisclosure,
    Grid,
    GridItem,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { Languages } from "../../intl-languages";
import { LanguageContext } from "../../context/languageContext";
import { intl, IntlKey } from "../../intl-languages";
import { isMobileScreen } from "../../common/breakPoints";
import ReactPlayer from "react-player/youtube";
import { RulesModal } from "./rulesModal";

export const AboutContainer: React.FC = () => {
    const breakPoint = useBreakpoint({ ssr: false });
    const isMobile = isMobileScreen(breakPoint);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return (
        <Box
            backgroundImage="url('/assets/bg_how.png')"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            bgColor="quiz_purple.50"
            color="whiteAlpha.900"
            minH={isMobile ? "1680px" : "1520px"}
        >
            <VStack w="100%" gap={5} pt={125}>
                <Center pt={5}>
                    <Heading>{intl.aboutHeading[langKey]}</Heading>
                </Center>
                <Flex w="100%" direction="column" gap={isMobile ? 10 : 14}>
                    <Box>
                        <Center>
                            <Box borderWidth={14} borderColor="quiz_purple.300" borderRadius={16}>
                                <iframe
                                    width={isMobile ? 340 : 920}
                                    height={isMobile ? 180 : 500}
                                    src="https://www.youtube.com/embed/TwUOQREaI5o?controls=0&showInfo=0&modestbranding=1&iv_load_policy=3&rel=0"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                                {/* <ReactPlayer
                                    width={isMobile ? 340 : 920}
                                    height={isMobile ? 180 : 500}
                                    playerVars={{
                                        showInfo: 0,
                                        rel: 0,
                                        modestbranding: 1,
                                        iv_load_policy: 3,
                                        controls: 2,
                                    }}
                                    url="https://www.youtube.com/watch?v=TwUOQREaI5o"
                                /> */}
                            </Box>
                        </Center>
                        <Center>
                            <VStack>
                                <Container
                                    textAlign="center"
                                    pt={6}
                                    pb={6}
                                    maxW={isMobile ? 320 : 570}
                                    bg="quiz_purple.900"
                                    borderBottomRadius={16}
                                >
                                    {intl.aboutDesc[langKey]}
                                </Container>
                                <Button
                                    bg="#8900F4"
                                    bgGradient="linear(to-br, #8900F4, #F400BF)"
                                    _hover={{ bg: "#8900F4" }}
                                    _active={{ bg: "#F400BF" }}
                                    size="lg"
                                    onClick={onOpen}
                                >
                                    {intl.navAbout[langKey]}
                                </Button>
                            </VStack>
                        </Center>
                    </Box>
                    <Flex wrap="wrap" direction="row" align="center" justify="center" gap={5}>
                        <Grid
                            templateColumns="repeat(8, 1fr)"
                            gap={2}
                            maxW={280}
                            justifyContent="space-evenly"
                            justifyItems="center"
                            alignContent="space-evenly"
                            alignItems="center"
                        >
                            <GridItem colSpan={4}>
                                <Image height={isMobile ? 100 : 170} objectFit="contain" src="/assets/how_1.png" />
                            </GridItem>
                            <GridItem colSpan={4}>
                                <Flex direction="column" justify="left">
                                    <Text fontWeight="bold" fontSize={isMobile ? "4xl" : "6xl"}>
                                        7+
                                    </Text>
                                    <Text fontWeight="bold" fontSize={isMobile ? "2xl" : "3xl"} whiteSpace="nowrap">
                                        {intl.aboutRounds[langKey]}
                                    </Text>
                                </Flex>
                            </GridItem>
                        </Grid>
                        <Grid
                            templateColumns="repeat(8, 1fr)"
                            gap={2}
                            maxW={280}
                            justifyContent="space-evenly"
                            justifyItems="center"
                            alignContent="space-evenly"
                            alignItems="center"
                        >
                            <GridItem colSpan={4}>
                                <Image height={isMobile ? 100 : 170} objectFit="contain" src="/assets/how_2.png" />
                            </GridItem>
                            <GridItem colSpan={4}>
                                <Flex direction="column" justify="left">
                                    <Text fontWeight="bold" fontSize={isMobile ? "4xl" : "6xl"}>
                                        2+
                                    </Text>
                                    <Text fontWeight="bold" fontSize={isMobile ? "2xl" : "3xl"} whiteSpace="nowrap">
                                        {intl.aboutHours[langKey]}
                                    </Text>
                                </Flex>
                            </GridItem>
                        </Grid>
                        <Grid
                            templateColumns="repeat(8, 1fr)"
                            gap={2}
                            maxW={300}
                            justifyContent="space-evenly"
                            justifyItems="center"
                            alignContent="space-evenly"
                            alignItems="center"
                        >
                            <GridItem colSpan={4}>
                                <Image height={isMobile ? 100 : 170} objectFit="contain" src="/assets/how_3.png" />
                            </GridItem>
                            <GridItem colSpan={4}>
                                <Flex direction="column" justify="left">
                                    <Text fontWeight="bold" fontSize={isMobile ? "4xl" : "6xl"}>
                                        2-8
                                    </Text>
                                    <Text fontWeight="bold" fontSize={isMobile ? "2xl" : "3xl"} whiteSpace="nowrap">
                                        {intl.aboutPerson[langKey]}
                                    </Text>
                                </Flex>
                            </GridItem>
                        </Grid>
                    </Flex>
                    <Center>
                        <Flex
                            w={isMobile ? "90%" : "70%"}
                            direction="row"
                            justify="center"
                            align="center"
                            wrap="wrap"
                            gap={3}
                            fontSize={isMobile ? "xs" : "sm"}
                        >
                            <Grid
                                w={400}
                                h={110}
                                borderWidth={2}
                                borderColor="quiz_pink.700"
                                bg="quiz_pink.70040"
                                templateRows="repeat(1, 1fr)"
                                templateColumns="repeat(10, 1fr)"
                                justifyContent="space-evenly"
                                justifyItems="center"
                                alignContent="space-evenly"
                                alignItems="center"
                                p={5}
                            >
                                <GridItem rowSpan={1} colSpan={3}>
                                    <Image height={85} objectFit="contain" src="/assets/how_know.png" />
                                </GridItem>
                                <GridItem rowSpan={1} colSpan={7}>
                                    <Container>
                                        <Text>{intl.aboutKnowledge[langKey]}</Text>
                                    </Container>
                                </GridItem>
                            </Grid>
                            <Grid
                                w={400}
                                h={110}
                                borderWidth={2}
                                borderColor="quiz_pink.700"
                                bg="quiz_pink.70040"
                                templateRows="repeat(1, 1fr)"
                                templateColumns="repeat(10, 1fr)"
                                justifyContent="space-evenly"
                                justifyItems="center"
                                alignContent="space-evenly"
                                alignItems="center"
                                p={5}
                            >
                                <GridItem rowSpan={1} colSpan={3}>
                                    <Image height={70} objectFit="contain" src="/assets/how_food.png" />
                                </GridItem>
                                <GridItem rowSpan={1} colSpan={7}>
                                    <Container>
                                        <Text>{intl.aboutFood[langKey]}</Text>
                                    </Container>
                                </GridItem>
                            </Grid>
                            <Grid
                                w={400}
                                h={110}
                                borderWidth={2}
                                borderColor="quiz_pink.700"
                                bg="quiz_pink.70040"
                                templateRows="repeat(1, 1fr)"
                                templateColumns="repeat(10, 1fr)"
                                justifyContent="space-evenly"
                                justifyItems="center"
                                alignContent="space-evenly"
                                alignItems="center"
                                p={5}
                            >
                                <GridItem rowSpan={1} colSpan={3}>
                                    <Image height={65} objectFit="contain" src="/assets/how_photo.png" />
                                </GridItem>
                                <GridItem rowSpan={1} colSpan={7}>
                                    <Container>
                                        <Text>{intl.aboutPhoto[langKey]}</Text>
                                    </Container>
                                </GridItem>
                            </Grid>
                            <Grid
                                w={400}
                                h={110}
                                borderWidth={2}
                                borderColor="quiz_pink.700"
                                bg="quiz_pink.70040"
                                templateRows="repeat(1, 1fr)"
                                templateColumns="repeat(10, 1fr)"
                                justifyContent="space-evenly"
                                justifyItems="center"
                                alignContent="space-evenly"
                                alignItems="center"
                                p={5}
                            >
                                <GridItem rowSpan={1} colSpan={3}>
                                    <Image height={70} objectFit="contain" src="/assets/how_friends.png" />
                                </GridItem>
                                <GridItem rowSpan={1} colSpan={7}>
                                    <Container>
                                        <Text>{intl.aboutFriends[langKey]}</Text>
                                    </Container>
                                </GridItem>
                            </Grid>
                        </Flex>
                    </Center>
                </Flex>
            </VStack>
            <RulesModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} lang={lang} />
        </Box>
    );
};
