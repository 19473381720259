export const isMobileScreen = (bp: string) => {
    switch (bp) {
        case "2xl":
        case "xl":
        case "lg":
            return false;
        default:
            return true;
    }
};
