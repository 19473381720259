/**
 * NOTE: properties in "intl", "IntlKey" and "Languages" should be named identically (case sensitive)
 */
export const intl = {
    // NAVBAR
    navSchedule: {
        en: "Schedule",
        한국어: "예약하기",
    },
    navRanking: {
        en: "Ranking",
        한국어: "순위",
    },
    navAbout: {
        en: "Rules",
        한국어: "게임 규칙",
    },
    navContacts: {
        en: "Contacts",
        한국어: "문의하기",
    },
    // HERO
    heroHeading: {
        en: "Korea's first bar quiz game~ Quiz GO!",
        한국어: "국내 최초 바 퀴즈 게임 Quiz GO!",
    },
    heroText: {
        en: "Gather your own team and engage in battle with other contestants. A variety of quizzes are presented, from general knowledge to specialized areas. Have fun getting to know each other! Time to accumulate knowledge! Join “Quiz Go”!",
        한국어: "나만의 팀을 모아 다른 참가자들과 배틀에 참여하세요. 일반 상식부터 전문 영역까지 다양한 퀴즈가 출제됩니다. 하나씩 알아가는 재미! 지식도 쌓이는 시간! “Quiz Go”와 함께 하세요!",
    },
    // UPCOMING EVENTS
    upcomingEventsHeading: {
        en: "Upcoming events",
        한국어: "게임 스케줄",
    },
    upcomingEventsShowMore: {
        en: "Show more",
        한국어: "자세히보기",
    },
    // EVENT CARD
    eventCardWhere: {
        en: "Where it is?",
        한국어: "오시는길",
    },
    eventCardFewPlacesLeft: {
        en: "Few places left",
        한국어: "마감 임박",
    },
    eventCardBook: {
        en: "Book",
        한국어: "예약하기",
    },
    eventCardReserveWaitingList: {
        en: "Reserve waiting list",
        한국어: "예약 대기자 명단",
    },
    eventCardReserveWaitingListTag: {
        en: "What is waiting list?",
        한국어: "대기자 명단 이란?",
    },
    eventCardReserveWaitingListPopover: {
        en: "We will contact you if team from reserve list will cancel it's reservation",
        한국어: "예비 명단에 있는 팀이 예약을 취소할 경우 연락드리겠습니다.",
    },
    eventCardNoPlacesLeft: {
        en: "No places left",
        한국어: "남은 자리가 없습니다",
    },
    eventCardPerPerson: {
        en: "per person",
        한국어: "1인당",
    },
    aboutHeading: {
        en: '"Quiz Go" What it is',
        한국어: '"Quiz Go" 란?',
    },
    aboutDesc: {
        en: "It is a team-specific game that can develop intelligence and creativity, and solve quizzes on various topics while enjoying it. A great opportunity to work together as a team to solve problems and have a fun experience. Quiz GO! Challenge now!",
        한국어: "지능과 창의력을 개발할 수 있고, 다양한 주제의 퀴즈들을 즐기면서 풀 수 있는 팀별 게임입니다. 팀끼리 힘을 합쳐서 문제를 풀고, 재미있는 경험을 할 수 있는 좋은 기회. Quiz GO! 지금 도전하세요!",
    },
    aboutRounds: {
        en: "Rounds",
        한국어: "개의 라운드",
    },
    aboutHours: {
        en: "Hours",
        한국어: "시간 플레이",
    },
    aboutPerson: {
        en: "Persons",
        한국어: "인 팀별 배틀",
    },
    aboutKnowledge: {
        en: "You can use not only your knowledge, but also life experience to win this game.",
        한국어: "다양한 형태의 퀴즈를 풀며 새로운 정보를 얻고 지식을 쌓을 수 있어요",
    },
    aboutFood: {
        en: "You can eat delicious food and drink while you play! Time to focus only on Quiz Go! without a cell phone for 2 hours!",
        한국어: "게임을 하면서 맛있는 음식과 음료를 먹을 수 있습니다! 2시간동안 핸드폰 없이 오로지 퀴즈에만 집중하는 시간!",
    },
    aboutPhoto: {
        en: "The top ranking teams will receive generous gifts. And all teams will receive a commemorative photo as a gift!",
        한국어: "상위 랭킹팀에게는 푸짐한 상품이, 모든 팀에게는 기념 사진을 선물로 드려요!",
    },
    aboutFriends: {
        en: "Don't miss this great opportunity to meet new people!",
        한국어: "다양하고 새로운 사람들을 만날 수 있는 절호의 기회를 놓치지 마세요!",
    },
    lastEvents: {
        en: "QUIZ GO! gallery",
        한국어: "QUIZ GO! 갤러리",
    },
    lastEventsOtherPhotos: {
        en: "More photos",
        한국어: "더 많은 사진들",
    },
    // FOOTER
    footerAnyQuestion: {
        en: "Any questions?",
        한국어: "질문이 있으십니까?",
    },
    footerBecomePartner: {
        en: "Become partner",
        한국어: "파트너 되기",
    },
    footerContacUs: {
        en: "Contact us",
        한국어: "문의하기",
    },
    // SCHEDULE
    scheduleFilterCity: {
        en: "City",
        한국어: "도시",
    },
    scheduleFilterCityPlaceholder: {
        en: "Select city",
        한국어: "도시 선택",
    },
    scheduleFilterPlace: {
        en: "Place",
        한국어: "장소",
    },
    scheduleFilterPlacePlaceholder: {
        en: "Select place",
        한국어: "위치 선택",
    },
    scheduleFilterDate: {
        en: "Date",
        한국어: "날짜",
    },
    scheduleFilterDatePlaceholder: {
        en: "Select date",
        한국어: "날짜 선택",
    },
    bookModalTeamName: {
        en: "Team name",
        한국어: "팀 이름",
    },
    bookModalEnterTeamName: {
        en: "Enter team name",
        한국어: "팀 이름 입력",
    },
    bookModalNameNotValid: {
        en: "Name may contain characters, digits and can't be more than 60 symbols including whitespaces",
        한국어: "이름은 문자, 숫자를 포함할 수 있으며 공백을 포함하여 60개를 초과할 수 없습니다",
    },
    bookModalTeamNameNotAvailable: {
        en: "Name is already taken. Please choose another name",
        한국어: "이미 사용 중인 이름입니다. 다른 이름을 선택하십시오",
    },
    bookModalTeamleaderName: {
        en: "Team leader name",
        한국어: "팀 리더 이름",
    },
    bookModalEnterTeamleaderName: {
        en: "Enter team leader name",
        한국어: "팀 리더 이름 입력",
    },
    bookModalTeammatesCount: {
        en: "Number of teammates",
        한국어: "팀원 수",
    },
    bookModalEmail: {
        en: "Email",
        한국어: "이메일",
    },
    bookModalEnterEmail: {
        en: "Enter email",
        한국어: "이메일 입력",
    },
    bookModalEmailNotValid: {
        en: "Email is not valid",
        한국어: "이메일이 유효하지 않습니다",
    },
    bookModalPhoneNumber: {
        en: "Team leader phone number",
        한국어: "팀원 리더 전화번호",
    },
    bookModalEnterPhoneNumber: {
        en: "8210xxxxxxxx",
        한국어: "8210xxxxxxxx",
    },
    bookModalPhoneNotValid: {
        en: "Phone number is not valid",
        한국어: "전화번호가 유효하지 않습니다",
    },
    bookModalFirstTime: {
        en: "First time playing the game",
        한국어: "게임이 처음이에요",
    },
    bookModalHasCertificate: {
        en: "We have bonus certificate",
        한국어: "우리는 보너스 인증서가 있습니다",
    },
    bookModalComment: {
        en: "Comment",
        한국어: "하고싶은 말",
    },
    bookModalLeaveAComment: {
        en: "Share how you heard about us or leave any request",
        한국어: "우리에 대해 어떻게 알게 되었는지 공유하거나 요청을 남겨주세요",
    },
    bookModalAlertFallbackBooking: {
        en: "Attention! You are registering for a reserve. After registration, you need to wait for a notification from us that there is available booking and you can participate in the game",
        한국어: "주목! 예비 등록 중입니다. 등록 후 예약이 가능하고 게임에 참여할 수 있다는 알림을 기다려야 합니다",
    },
    bookModalAlertBookSuccess: {
        en: "Your booking was completed successfully! We will contact you soon to confirm the booking! For any questions you can message us or make a call: ",
        한국어: "예약이 성공적으로 완료되었습니다! 예약 확인을 위해 곧 연락드리겠습니다! 질문이 있으면 메시지를 보내거나 전화를 걸 수 있습니다: ",
    },
    bookModalAlertBookFail: {
        en: "Oops! Something went wrong! Please reload page and try again! If it doesn't help you can try later or contact us via: ",
        한국어: "오류! 페이지를 다시 로딩한 후 시도하세요. 그래도 해결되지 않으면, 나중에 다시 시도하거나, 밑에 번호로 문의하세요. ",
    },
    bookModalOverallPrice: {
        en: "Overall price",
        한국어: "총 금액",
    },
    rulesOverall: {
        en: "- QuizGo has a total of 7 rounds.\n- Each round consists of 6 quizzes. (* Round 6 consists of 3 questions.)\n- There are two 10-minute breaks between rounds 3 and 6.\n- Discuss with your teammates and answer the “answer sheet” provided on each table. You must write them down.\n- After each round, hand in your answer sheet to the Quizman.",
        한국어: "- 퀴즈고는 총 7개의 라운드로 진행됩니다.\n- 한 라운드당 6개의 퀴즈로 구성되어있습니다. (* 6라운드는 3문제입니다.)\n- 3라운드와 6라운드 사이에 10분씩 총 2번 휴식시간이 있습니다.\n- 팀원들과 의논하여 각 테이블에 비치되어있는 “답안지” 에 답을 적어야 합니다.\n- 각 라운드가 끝나면 답안지를 퀴즈맨에게 제출해주세요.",
    },
    rulesFirstRoundHeading: {
        en: "* Round 1 – Warm-up",
        한국어: "* 1라운드 – 워밍업",
    },
    rulesFirstRound: {
        en: "- Questions on common sense, logic and various topics.\n- Multiple choice (4 options)\n- Time limit: 30 seconds per quiz.\n- Score: 1 point",
        한국어: "- 상식, 논리 및 다양한 주제에 대한 문제입니다.\n- 객관식 (4개의 보기)\n- 제한시간 : 한 퀴즈당 30초.\n- 점수 : 1점",
    },
    rulesSecondRoundHeading: {
        en: "* Round 2 – Brainstorming",
        한국어: "* 2라운드 – 브레인 스토밍",
    },
    rulesSecondRound: {
        en: "- Questions on common sense, logic, and various topics.\n- Short answer questions\n- Time limit: 60 seconds per second quiz\n- Score: 1 point",
        한국어: "- 상식, 논리 및 다양한 주제에 대한 문제입니다.\n- 주관식\n- 제한시간 : 힌 퀴즈당 60초\n- 점수 : 1점 ",
    },
    rulesThirdRoundHeading: {
        en: "* Round 3 – Music & Movie",
        한국어: "* 3라운드 – 뮤직&무비",
    },
    rulesThirdRound: {
        en: "- Voice quiz questions about popular culture such as songs and movies.\n- Short answer\n- Time limit: 30 seconds per quiz\n- Score: 1 point",
        한국어: "- 노래와 영화 등 대중문화에 관한 음성퀴즈 문제입니다/\n- 주관식\n- 제한시간 : 한 퀴즈당 30초\n- 점수 : 1점",
    },
    rulesFourthRoundHeading: {
        en: "* Round 4 – Secret theme",
        한국어: "* 4라운드 – 시크릿 테마",
    },
    rulesFourthRound: {
        en: "- If you know the final topic, you can easily solve the 6 quizzes.\n- Short answer\n- Time limit: 60 seconds per quiz\n- Score: 1 point\n~ Example: If the “each” correct answer for 6 quizzes is 'Gift, Carol, Home Alone, Tree, Jesus, Holiday', the final topic is Christmas. It is a quiz question within a quiz where you can find out what you can notice and find out that the rest of the questions you have not been able to guess will also be correct answers related to 'Christmas'!",
        한국어: "- 최종주제를 알면 6개의 퀴즈 정답을 쉽게 풀 수 있는 문제입니다.\n- 주관식\n- 제한시간 : 한 퀴즈당 60초\n- 점수 : 1점\n~ 예시 : 6개의 퀴즈에 대한 “각각의” 정답이 ‘선물, 캐롤, 나홀로집에, 트리, 예수, 연휴’ 라면 마지막 최종 주제는 크리스마스입니다.\n정답 몇개 정도만 알아낸다면, 주제가 ‘크리스마스’ 라는 것을 눈치껏 알아낼 수 있고, 맞추지 못했었던 나머지 문제들도 ‘크리스마스’와 관련된 정답일 것이라는 알아낼 수 있는 퀴즈 속의 퀴즈 문제입니다!",
    },
    rulesFifthRoundHeading: {
        en: "* Round 5 – Jukebox",
        한국어: "* 5라운드 – 주크박스",
    },
    rulesFifthRound: {
        en: "- Music quiz to guess the “singer” of the song played for 15 seconds.\n- Play 3 songs sung by one singer for 15 seconds each.\n- Short answer\n- Score : 2 points for correct song 1 / 1 point for correct song 2 / 0.5 points for correct song 3",
        한국어: "- 15초 동안 들려주는 노래의 “가수”를 맞추는 음악 퀴즈입니다.\n- 한 가수가 부른 3개의 노래를 각각 15초씩 들려줍니다.\n- 주관식\n- 점수 : 1번 노래로 맞출 시 2점 / 2번 노래로 맞출 시 1점 / 3번 노래로 맞출시 0.5 점",
    },
    rulesSixthRoundHeading: {
        en: "* Round 6 – Final",
        한국어: "* 6라운드 – 파이널",
    },
    rulesSixthRound: {
        en: "- This is the most difficult quiz, covering various topics such as common sense, reasoning, intuition, and science.\n- It consists of only 3 questions.\n- Short answer\n- Time limit: one quiz per 60 seconds\n- Score: 2 points",
        한국어: "- 가장 최고 난이도의 퀴즈로서, 상식, 추리, 직관, 과학 등 다양한 주제입니다.\n- 총 3문제로만 구성되어있습니다.\n- 주관식\n- 제한시간 : 한 퀴즈당 60초\n- 점수 : 2점",
    },
    rulesSeventhRoundHeading: {
        en: "* Round 7 – Betting",
        한국어: "* 7 라운드 – 배팅",
    },
    rulesSeventhRound: {
        en: "- Quiz on various topics. Instead, it is a turn-around round where you can place bets.\n- Time limit: 30 seconds OR 60 seconds per quiz\n- How to bet: Write down the correct answer and mark “+1”\n- Score: When the answer is correct Score with double points -> Get 2 points / Points deducted for incorrect answers -> -1 point\n~ Example: What if you placed bets on all 6 betting quizzes? \nIf all answers are correct, you get a total of 12 points instead of 6 points, and if all answers are incorrect, you get -6 points instead of 0 points.",
        한국어: "- 다양한 주제의 퀴즈입니다. 대신, 배팅을 걸 수 있는 역전의 기회 라운드입니다.\n- 제한시간 : 한 퀴즈당 30초 OR 60초\n- 배팅 방법 : 정답을 적고 “+1” 이라고 표시하기\n- 점수 : 정답일시 두배의 점수로 득점 -> 2점 획득 / 오답일시 점수 차감 -> –1점\n~ 예시 : 만약 6개의 배팅 퀴즈에 모두 배팅을 걸었다면? \n다 정답일 시에는 6점이 아닌 총 12점을 획득, 다 오답일 시에는 0점이 아닌 –6점을 획득.",
    },
    rulesFinal: {
        en: "- If you have any questions before or during the game, feel free to ask the quiz man wearing a QuizGo T-shirt!\n- Make the most of the QuizGo venue! You can enjoy QuizGo more fun and exciting with various snacks and drinks!",
        한국어: "- 게임 전 혹은 중간에 궁금한 점이 있다면 언제든지 퀴즈고 티셔츠를 입은 퀴즈맨에게 문의해주세요!\n- 퀴즈고가 펼쳐지는 장소를 최대한 이용해주세요! 다양한 안주와 술과 함께라면 퀴즈고를 더 재밌고 신나게 즐기실 수 있습니다!",
    },
};

export type IntlKey = {
    en: string;
    한국어: string;
};

export enum Languages {
    한국어,
    en,
}

export const WeekdayShortNames = {
    Sunday: {
        en: "Su",
        한국어: "일",
    },
    Monday: {
        en: "Mo",
        한국어: "월",
    },
    Tuesday: {
        en: "Tu",
        한국어: "화",
    },
    Wednesday: {
        en: "We",
        한국어: "수",
    },
    Thursday: {
        en: "Th",
        한국어: "목",
    },
    Friday: {
        en: "Fr",
        한국어: "금",
    },
    Saturday: {
        en: "Sa",
        한국어: "토",
    },
};

export const LanguageCode = {
    getCode(lang: Languages) {
        switch (lang) {
            case Languages.en:
                return "en-US";
            case Languages.한국어:
                return "ko-KR";
            default:
                throw new Error(`There is no language code for ${lang} language`);
        }
    },
};
