import { Container, Text, VStack } from "@chakra-ui/layout";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/modal";
import React, { useContext } from "react";
import { LanguageContext } from "../../context/languageContext";
import { intl, IntlKey, Languages } from "../../intl-languages";

interface IProps {
    onOpen: () => void;
    onClose: () => void;
    isOpen: boolean;
    lang: Languages;
}

export const RulesModal: React.FC<IProps> = ({ onOpen, onClose, isOpen }) => {
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
            <ModalOverlay h="100%" w="100%" bg="quiz_purple.90060" />
            <ModalContent bgColor="white" p={5}>
                <ModalCloseButton />
                <ModalHeader textAlign="center">{intl.navAbout[langKey]}</ModalHeader>
                <ModalBody>
                    <Container>
                        <VStack align="left" gap={5}>
                            <Text whiteSpace="break-spaces">{intl.rulesOverall[langKey]}</Text>
                            <VStack align="left">
                                <Text whiteSpace="break-spaces" fontWeight="bold">
                                    {intl.rulesFirstRoundHeading[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" pl={5}>
                                    {intl.rulesFirstRound[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" fontWeight="bold">
                                    {intl.rulesSecondRoundHeading[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" pl={5}>
                                    {intl.rulesSecondRound[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" fontWeight="bold">
                                    {intl.rulesThirdRoundHeading[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" pl={5}>
                                    {intl.rulesThirdRound[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" fontWeight="bold">
                                    {intl.rulesFourthRoundHeading[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" pl={5}>
                                    {intl.rulesFourthRound[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" fontWeight="bold">
                                    {intl.rulesFifthRoundHeading[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" pl={5}>
                                    {intl.rulesFifthRound[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" fontWeight="bold">
                                    {intl.rulesSixthRoundHeading[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" pl={5}>
                                    {intl.rulesSixthRound[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" fontWeight="bold">
                                    {intl.rulesSeventhRoundHeading[langKey]}
                                </Text>
                                <Text whiteSpace="break-spaces" pl={5}>
                                    {intl.rulesSeventhRound[langKey]}
                                </Text>
                            </VStack>

                            <Text whiteSpace="break-spaces">{intl.rulesFinal[langKey]}</Text>
                        </VStack>
                    </Container>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </ModalContent>
        </Modal>
    );
};
