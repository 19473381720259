import { Flex, Heading, Text } from "@chakra-ui/react";
import { useRouteError } from "react-router-dom";

type RouteError = {
    status: string,
    statusText: string;
    message: string;
};

const ErrorPage = () => {
    const error = useRouteError() as RouteError;

    return (
        <Flex align="center" direction="column" justify="center">
            <Heading>Oops, something gone wrong!</Heading>
            <Text>Unexpected error has occured!</Text>
            <Text>{error.status} {error.statusText}</Text>
            <Text>{JSON.stringify(error)}</Text>
        </Flex>
    );
};

export default ErrorPage;
