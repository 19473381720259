import React, { useState } from "react";
import { Languages } from "../intl-languages";

interface ILanguageContextProps {
    lang: Languages;
    setLanguage: (lang: Languages) => void;
}

export const LanguageContext = React.createContext<ILanguageContextProps>({
    lang: Languages.en,
    setLanguage: () => {
        throw new Error("Language context has not been inited");
    },
});

interface ILanguageContextProviderProps {
    lang: Languages;
    children?: React.ReactNode;
}

export const LanguageContextProvider: React.FC<ILanguageContextProviderProps> = ({ lang, children }) => {
    const [currLang, setLanguage] = useState(lang);

    return <LanguageContext.Provider value={{ lang: currLang, setLanguage }}>{children}</LanguageContext.Provider>;
};
