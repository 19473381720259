import { Box, Button, Grid, GridItem, Heading, Text, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/languageContext";
import { intl, IntlKey, Languages } from "../../intl-languages";

export const HeroDesktop: React.FC = () => {
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return (
        <Box
            h="530"
            bg="white"
            pt={4}
            bgColor="quiz_purple.50"
            backgroundImage="url('/assets/hero.png')"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            wordBreak="keep-all"
        >
            <Grid w="100%" h="100%" templateColumns="repeat(2, 1fr)" templateRows="repeat(5, 1fr)">
                <GridItem colSpan={2} rowSpan={1}></GridItem>
                <GridItem colSpan={1} rowSpan={2}>
                    <VStack color="whiteAlpha.900" gap={3} pl="15%" align="left">
                        <Heading w={lang == Languages.한국어 ? "95%" : "85%"} size="3xl">
                            {intl.heroHeading[langKey]}
                        </Heading>
                        <Box w={lang == Languages.한국어 ? "75%" : "90%"} pr="10%">
                            <Text fontSize={lang == Languages.한국어 ? "md" : "xs"}>{intl.heroText[langKey]}</Text>
                        </Box>
                        <Box w="90%">
                            <Link to="/schedule">
                                <Button
                                    rightIcon={<FiChevronRight />}
                                    bg="#8900F4"
                                    bgGradient="linear(to-br, #8900F4, #F400BF)"
                                    _hover={{ bg: "#8900F4" }}
                                    _active={{ bg: "#F400BF" }}
                                >
                                    Play
                                </Button>
                            </Link>
                        </Box>
                    </VStack>
                </GridItem>
            </Grid>
        </Box>
    );
};
